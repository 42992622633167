.menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  display: inline-flex;
  width: max-content;
  flex-direction: row;
  gap: 12px;

  li {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin: -4px auto 0;
        width: 50%;
        height: 4px;
        background: var(--info);
      }
    }

    &.selected {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin: -4px auto 0;
        width: 50%;
        height: 4px;
        background: var(--info);
      }
    }
  }
}
