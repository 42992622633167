.background {
    background-image: url('../../public/assets/img/background.png');
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
	z-index: -1;
	opacity: 0.3;
}